import React from "react"
import { useAnalytics } from "@app/hooks/useAnalytics"

export const withSection =
  (Component: React.FC<any>) =>
  ({ name, item, ...props }: any) => {
    const { trackPromoImpression, trackPromoClick, VisibilitySensor } = useAnalytics()

    Component.displayName = name
    return name === "Hero" ? (
      <Component {...props} {...item} handleTrackingClick={() => trackPromoClick({ ...item?.analytics })} />
    ) : (
      <VisibilitySensor onChange={(visible: boolean) => visible && trackPromoImpression({ ...item?.analytics })}>
        <Component {...props} {...item} handleTrackingClick={() => trackPromoClick({ ...item?.analytics })} />
      </VisibilitySensor>
    )
  }
