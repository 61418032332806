import { useCallback } from "react"
import { getGatsbyImageData } from "gatsby-source-sanity"

import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"

export const useImage = () => {
  const {
    services: { sanity },
  } = useConfigContext()
  const {
    helpers: { handleize },
  } = useCore()

  const filename = useCallback(
    ({ alt, asset }) => `${handleize(asset?.originalFilename?.split(`.`)?.[0] || alt)}.${asset?.extension}`,
    [handleize]
  )

  const withFilename = useCallback(
    (fluidImage = {}, name = "image.jpg", properties = ["src", "srcSet"]) => ({
      ...fluidImage,
      ...properties?.reduce((o, key) => ({ ...o, [key]: fluidImage[key]?.split("?")?.join(`/${name}?`) }), {}),
    }),
    []
  )

  const getGatsbyImage = useCallback(
    (image: any, options: any = null) =>
      image?.asset?.id
        ? {
            alt: image?.alt,
            dimensions: image?.asset?.metadata?.dimensions,
            ...withFilename(getGatsbyImageData(image, options || {}, sanity)?.images?.fallback, filename(image)),
          }
        : image,
    [filename, sanity, withFilename]
  )

  const getResponsiveImage = useCallback(
    (image: any, options: any = null) => ({
      desktop: getGatsbyImage(image?.desktop, options?.desktop || options || {}),
      mobile: getGatsbyImage(image?.mobile?.asset ? image?.mobile : image?.desktop, options?.mobile || options?.desktop || options || {}),
    }),
    [getGatsbyImage]
  )

  const scaleShopifyImage = useCallback((imageUrl, sizeStr) => {
    if (!imageUrl.includes("cdn.shopify.com")) {
      return imageUrl
    }

    return imageUrl.replace(/^(.+?)(.(?:png|jpg|webp).+)$/, `$1_${sizeStr}$2`)
  }, [])

  return { getGatsbyImage, getResponsiveImage, scaleShopifyImage }
}
