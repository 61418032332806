import React from "react"
import { Box } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { useImage } from "@app/hooks/useImage"
import { withSection } from "@app/hoc/Section"
import { useRoutes } from "@app/hooks/useRoutes"
import HeroItem from "./HeroItem"
import "swiper/swiper.min.css"
import { css } from "@emotion/react"

type Props = {
  items: Array<any>
  handleTrackingClick: () => void
  paddingTop: number
  paddingBottom: number
  paddingMobileTop: number
  paddingMobileBottom: number
}

const Hero: React.FC<Props> = ({
  items: rawItems,
  handleTrackingClick,
  paddingTop,
  paddingBottom,
  paddingMobileTop,
  paddingMobileBottom,
}) => {
  const { getResponsiveImage } = useImage()
  const { urlResolver } = useRoutes()

  const items = rawItems?.map(item => ({
    ...item,
    image: getResponsiveImage(item?.image),
    key: item?._key,
    link: urlResolver(item?.link),
  }))

  const sectionSpacing = css`
    padding-top: ${paddingMobileTop || 0}px;
    padding-bottom: ${paddingMobileBottom || 0}px;
    @media (min-width: 768px) {
      padding-top: ${paddingTop || 0}px;
      padding-bottom: ${paddingBottom || 0}px;
    }
  `

  return (
    <Box position="relative" css={sectionSpacing}>
      {items?.length > 1 ? (
        <Swiper>
          {items?.map((item, index) => (
            <SwiperSlide key={item?.key?.toString() + index}>
              <HeroItem key={item?.key?.toString() + index} item={item} handleTrackingClick={handleTrackingClick} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <HeroItem item={items?.[0]} handleTrackingClick={handleTrackingClick} />
      )}
    </Box>
  )
}

export default React.memo(withSection(Hero))
