import React from "react"
import { AspectRatio, Box, Button, Container, Flex, Heading, Text, Image } from "@chakra-ui/react"
import { useMediaMobile } from "@app/hooks/useMedia"
import Link from "@app/components/Link"

type Props = {
  item: any
  handleTrackingClick: () => void
}

const HeroItem: React.FC<Props> = ({ item, handleTrackingClick }) => {
  const isMobile = useMediaMobile()
  const hasMobileImage = item?.image?.mobile?.src
  const hasDesktopImage = item?.image?.desktop?.src
  const isImage = (item?.image?.desktop?.src && !isMobile) || (item?.image?.mobile?.src && isMobile)

  const textColor = item?.buttonVariant == "inverted" ? "white" : "black"
  const floatsOnMobile = item.floatsOnMobile
  const floatsOnDesktop = item.floatsOnDesktop
  const isHorizontallyCentered = item.horizontalAlignment === "center"

  const overrideButtonVariant = (isMobile && !floatsOnMobile) || (!isMobile && !floatsOnDesktop)
  const flexToAlign = {
    "flex-end": "right",
    "flex-start": "left",
    center: "center",
  }

  const textAlign = flexToAlign[item.horizontalAlignment]

  return (
    <Container as="section" maxW={item.isContained ? "container.xxl" : "100%"} px={item.isContained ? [4, 16] : "0"}>
      <Box position="relative" w="100%" mx="auto">
        {item?.image?.desktop && (
          <Box
            textAlign="center"
            bgColor={item?.image?.desktop?.backgroundColor}
            position="relative"
            w="100%"
            d={["none", "block"]}
            _after={{
              content: '""',
              position: "absolute",
              inset: 0,
            }}
          >
            <AspectRatio w="100%" ratio={item.ratioDesktop || 2.13} objectFit="cover" objectPosition="center">
              <Image {...item?.image?.desktop} />
            </AspectRatio>
          </Box>
        )}
        {item?.image?.mobile && (
          <Box textAlign="center" bgColor={item?.image?.mobile?.backgroundColor} w="100%" d={["block", "none"]}>
            <AspectRatio w="100%" ratio={item.ratioMobile || 0.9} objectFit="cover" objectPosition="center">
              <Image {...item?.image?.mobile} />
            </AspectRatio>
          </Box>
        )}
        <Flex
          position={[floatsOnMobile ? "absolute" : "relative", floatsOnDesktop && isImage ? "absolute" : "relative"]}
          w="100%"
          h="100%"
          minH={[hasMobileImage ? "unset" : "60vh", hasDesktopImage ? "unset" : "60vh"]}
          top="0"
          left="0"
          alignItems={["flex-end", item.verticalAlignment || "flex-end"]}
          justifyContent={["flex-end", item.horizontalAlignment || "flex-start"]}
        >
          <Box p={[8, 16]} textAlign={["center", textAlign]} w="100%" maxW={["100%", "600px"]} px={[6, 24]} pl={[6, "64px"]}>
            {item?.title && (
              <Heading
                as="h2"
                size="h2"
                px={[8, 0]}
                color={[!floatsOnMobile ? "black" : textColor, !floatsOnDesktop ? "black" : textColor]}
              >
                {item?.title}
              </Heading>
            )}
            {item?.content && <Text>{item?.content}</Text>}
            <Box mt={(item?.content || item?.title) && 8}>
              <Button
                variant={overrideButtonVariant ? "outline" : item?.buttonVariant || "outline"}
                w={["100%", "auto"]}
                as={props => <Link title={item?.link?.title} to={item?.link?.url} onClick={handleTrackingClick} {...props} />}
                d={"inline-flex"}
              >
                {item?.link?.title}
              </Button>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  )
}

export default React.memo(HeroItem)
