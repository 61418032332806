import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "@app/components/Sections/Sections"

export type Props = PageProps<GatsbyTypes.PageHomepageQuery, GatsbyTypes.PageHomepageQueryVariables>

export const query = graphql`
  query PageHomepage {
    page: sanityPageHomepage(handle: { current: { eq: "default" } }) {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
    pages: allSanityPageHomepage {
      edges {
        node {
          title
          url
          handle {
            current
          }
          metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
          content: _rawContent(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
