import React, { useMemo, lazy } from "react"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { usePromotions } from "@app/hooks/usePromotions"
import Main from "@app/components/Main"
import Hero from "./Hero/Hero"
import type { PageProps } from "@root/types/global"

const Sections: React.FC<PageProps<any>> = ({ page, pages }) => {
  const {
    helpers: { ErrorBoundary, isDomReady },
  } = useCore()
  const { edgeNormaliser } = useShopify()
  const { homepage } = usePromotions()

  const item = useMemo(
    () => edgeNormaliser(pages)?.find((item: any) => item?.handle?.current === homepage) || page,
    [page, pages, homepage, edgeNormaliser]
  )
  const content = item?.content
  const sectionHero = useMemo(() => (content?.[0]?._type === "sectionHero" ? content?.[0] : null), [content])
  const sectionOther = useMemo(() => {
    const arr = sectionHero ? content?.slice(1) : content || []
    return arr?.map(section => {
      const name = section._type?.replace("section", "")
      const SectionComponent = lazy(() => import(`./${name}/${name}`))
      return <SectionComponent key={section._key?.toString()} name={name} {...section} />
    })
  }, [content, sectionHero])

  return (
    <Main minH="calc(100vh - 39.1em)">
      {sectionHero && <Hero name="Hero" {...sectionHero} />}
      {isDomReady && <ErrorBoundary>{sectionOther}</ErrorBoundary>}
    </Main>
  )
}

export default Sections
